import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.purple.lighten1, // #E53935
        secondary: colors.red.lighten4, // #FFCDD2
        accent: colors.purple.accent1 // #3F51B5
        // info: colors.red.darken1,
        // warning: colors.red.darken1,
        // error: colors.red.darken1,
        // success: colors.red.darken1,
      }
    }
  }
});

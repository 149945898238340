


















































































































import Vue from "vue";

export default Vue.extend({
  name: "HelloWorld",

  data: () => ({
    links: [
      {
        title: "Twitch",
        icon: "mdi-twitch",
        href: "https://twitch.tv/themysticnarwhal"
      },
      {
        title: "Discord",
        icon: "mdi-discord",
        href: "https://discord.gg/rAQ5zUxH3V"
      },
      {
        title: "Instagram",
        icon: "mdi-instagram",
        href: "https://www.instagram.com/themysticnarwhal/"
      }
    ]
  })
});
